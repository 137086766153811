@import 'mixins';
@import 'variables';

.moedas-safe-zone {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;

  @include respond-to('medium') {
    height: $safe-zone-height;
  }

  &__container {
    max-width: $max-width-swiper-mobile;
    padding: 4rem 23px 2rem;
    width: 100%;
    @include respond-to('medium') {
      max-width: $max-width;
    }
    .swiper-container {
      height: 300px;
    }

    .swiper-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      &-bullet {
        width: 12px;
        height: 12px;
        &-active {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .payment {
    max-width: 380px;
    text-align: center;
    display: grid;
    justify-content: center;
    align-items: center;
    @include respond-to('medium') {
      padding: 2rem;
    }

    img {
      margin: 0 auto 2.37rem auto;
    }

    h4 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 9px;
    }

    p {
      color: #757575;
      font-size: 14px;
      line-height: 1.36;
    }
  }
}
