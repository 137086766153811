@import '../../../../assets/styles/utils/variables';
@import '../../../../assets/styles/utils/_mixins';

.moedas-footer {
  background: #f6f7f9;
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 0 19px;
  border-top: solid 1px #e3e3e3;
  @include respond-to('small') {
    justify-content: flex-start;
  }

  @include respond-to('medium') {
    padding: 0;
    height: $hight-footer;
    justify-content: center;
  }

  &__container {
    display: grid;
    grid-template-columns: 2fr;
    width: 100%;
    max-width: $max-width;

    @include respond-to('medium') {
      grid-template-columns: repeat(5, 2fr);
    }

    img {
      width: 100%;
      max-width: 218px;
      margin: 2.5rem 2rem 1rem 0;

      @include respond-to('medium') {
        margin-top: 0.5rem;
      }
    }

    ul {
      li {
        font-size: 15px;
        color: $secondary-on-color;
        font-weight: 600;
        margin-bottom: 1rem;
      }
    }
  }

  address {
    align-self: flex-start;

    p {
      color: $secondary-on-color;
    }
  }
}
