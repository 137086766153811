//Swiper CSS
@import 'swiper/swiper-bundle.min.css';
@import 'swiper/scss/autoplay';

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

@font-face {
   font-family: open-sans;
   src: url('/assets/fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
   font-family: open-sans-semibold;
   src: url('/assets/fonts/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
   font-family: open-sans-bold;
   src: url('/assets/fonts/OpenSans-Bold.ttf') format('truetype');
   font-weight: 700;
   font-style: bold;
}

@font-face {
   font-family: baloo2;
   src: url('/assets/fonts/Baloo2-Regular.ttf') format('truetype');
}

@font-face {
   font-family: baloo2-semibold;
   src: url('/assets/fonts/Baloo2-SemiBold.ttf') format('truetype');
}

@import 'assets/styles/main.scss';

.container {
   width: 100%;
   max-width: 1248px;
   margin: 0 auto;
   padding: 0 16px;
}

html,
body {
   height: 100%;
}
