@import 'mixins';
@import 'variables';

.moedas-auth {
   @include respond-to('medium') {
      overflow-x: hidden;
      background: $primary-color;
      width: 100%;
      height: 100vh;
   }

   &-header {
      overflow-x: hidden;
      background: $primary-color;
      display: flex;
      height: 100px;
      justify-content: space-between;

      @include respond-to('medium') {
         height: auto;
         width: 100%;
         display: grid;
         grid-template-columns: 1fr 1fr 1fr 2fr;
         grid-template-areas: '. . img1 img2';
      }

      img:first-child {
         padding-left: 1rem;
         width: 100%;
         max-width: 160px;

         @include respond-to('medium') {
            max-width: inherit;
            padding-left: 0;
            align-self: center;
            padding: 0;
            height: 38px;
            grid-area: img1;
         }
      }

      img:last-child {
         width: 200px;
         object-fit: cover;
         object-position: 20px 0px;
         height: 94px;

         @include respond-to('medium') {
            width: 77%;
            object-fit: inherit;
            object-position: inherit;
            grid-area: img2;
            height: inherit;
         }
      }
   }

   &__container {
      width: 100%;
      margin: 0 auto;
      @include respond-to('medium') {
         max-width: 422px;
      }

      &-form {
         border-top-left-radius: 7px;
         border-top-right-radius: 7px;
         background: white;
         display: grid;
         padding: 0 19px;
         position: relative;
         top: -6px;

         @include respond-to('medium') {
            background: white;
            padding: 0 46px;
            margin-top: 75px;
            position: inherit;
            top: 0;
         }

         h1 {
            font-size: 1.25rem;
            margin-top: initial;
            padding-top: 22px;

            @include respond-to('medium') {
               margin: 1rem 0 23px 0;
            }
         }

         label {
            margin-bottom: 9px;
            font-size: 0.9rem;
            color: #707070;
         }

         input {
            margin-bottom: 1rem;
            border: solid 1px #a5a5a5;
         }
      }

      &-actions {
         display: grid;
         gap: 20px;
         width: 100%;
         text-align: center;
         @include respond-to('medium') {
            background: white;
            border-bottom-left-radius: 7px;
            border-bottom-right-radius: 7px;
         }

         a {
            color: $primary-color;
            line-height: 1.36;
         }

         > span {
            color: #757575;
            font-size: 14px;
            line-height: 1.36;
            font-family: $font-family-moedas, sans-serif;

            a {
               font-weight: 600;
            }
         }

         hr {
            margin: 0 19px;
            @include respond-to('medium') {
               margin: 0;
            }
         }

         &-login {
            margin: 0 1rem 0 1rem;
            padding: 1rem 1rem;
            font-size: 1rem;

            @include respond-to('medium') {
               margin: 0 auto;
               width: 100%;
               max-width: 330px;
            }
         }

         hr {
            background: $background-color;
            opacity: 0.2;

            @include respond-to('medium') {
               margin-top: 1rem;
            }
         }

         &-register {
            color: $secondary-on-color;
            padding: 0.8rem;
            background: $background-on-color;
            border-radius: 7px;
            margin: 0 19px;

            @include respond-to('medium') {
               background: white;
               margin-bottom: 1rem;
            }

            a {
               font-weight: 700;
            }
         }

         a:last-child {
            margin: 0 auto;

            @include respond-to('medium') {
               background: white;
            }
         }

         .help {
            @include respond-to('medium') {
               display: none;
            }
         }
      }

      .help2 {
         display: flex;
         justify-content: center;
         margin-top: 1.5rem;
         color: white;
      }
   }
}
