.flat-alipay {
   background: url('./../../img/paymenticons.svg') 14.285714285714286% 0 no-repeat;
   width: 122px;
   height: 77.36px;
}
.flat-amex {
   background: url('./../../img/paymenticons.svg') 71.42857142857143% 11.104731281580156% no-repeat;
   width: 122px;
   height: 77.36px;
}
.flat-default {
   background: url('./../../img/paymenticons.svg') 0 11.104731281580156% no-repeat;
   width: 122px;
   height: 77.36px;
}
.flat-diners {
   background: url('./../../img/paymenticons.svg') 14.285714285714286% 11.104731281580156% no-repeat;
   width: 122px;
   height: 77.36px;
}
.flat-discover {
   background: url('./../../img/paymenticons.svg') 0 22.20946256316031% no-repeat;
   width: 122px;
   height: 77.36px;
}
.flat-elo {
   background: url('./../../img/paymenticons.svg') 14.285714285714286% 22.20946256316031% no-repeat;
   width: 122px;
   height: 77.36px;
}
.flat-hipercard {
   background: url('./../../img/paymenticons.svg') 28.571428571428573% 0 no-repeat;
   width: 122px;
   height: 77.36px;
}
.flat-jcb {
   background: url('./../../img/paymenticons.svg') 28.571428571428573% 11.104731281580156% no-repeat;
   width: 122px;
   height: 77.36px;
}
.flat-maestro {
   background: url('./../../img/paymenticons.svg') 0 33.31419384474047% no-repeat;
   width: 122px;
   height: 77.36px;
}
.flat-maestro-old {
   background: url('./../../img/paymenticons.svg') 14.285714285714286% 33.31419384474047% no-repeat;
   width: 122px;
   height: 77.36px;
}
.flat-mastercard {
   background: url('./../../img/paymenticons.svg') 28.571428571428573% 33.31419384474047% no-repeat;
   width: 122px;
   height: 77.36px;
}
.flat-mastercard-old {
   background: url('./../../img/paymenticons.svg') 42.857142857142854% 0 no-repeat;
   width: 122px;
   height: 77.36px;
}
.flat-paypal {
   background: url('./../../img/paymenticons.svg') 42.857142857142854% 11.104731281580156% no-repeat;
   width: 122px;
   height: 77.36px;
}
.flat-security-code {
   background: url('./../../img/paymenticons.svg') 42.857142857142854% 22.20946256316031% no-repeat;
   width: 122px;
   height: 77.36px;
}
.flat-unionpay {
   background: url('./../../img/paymenticons.svg') 0 44.41892512632062% no-repeat;
   width: 122px;
   height: 77.36px;
}
.flat-verve {
   background: url('./../../img/paymenticons.svg') 14.285714285714286% 44.41892512632062% no-repeat;
   width: 122px;
   height: 77.36px;
}
.flat-visa {
   background: url('./../../img/paymenticons.svg') 28.571428571428573% 44.41892512632062% no-repeat;
   width: 122px;
   height: 77.36px;
}
.mono-alipay {
   background: url('./../../img/paymenticons.svg') 42.857142857142854% 44.41892512632062% no-repeat;
   width: 122px;
   height: 77.36px;
}
.mono-amex {
   background: url('./../../img/paymenticons.svg') 0 55.52365640790078% no-repeat;
   width: 122px;
   height: 77.36px;
}
.mono-default {
   background: url('./../../img/paymenticons.svg') 14.285714285714286% 55.52365640790078% no-repeat;
   width: 122px;
   height: 77.36px;
}
.mono-diners {
   background: url('./../../img/paymenticons.svg') 28.571428571428573% 55.52365640790078% no-repeat;
   width: 122px;
   height: 77.36px;
}
.mono-discover {
   background: url('./../../img/paymenticons.svg') 42.857142857142854% 55.52365640790078% no-repeat;
   width: 122px;
   height: 77.36px;
}
.mono-elo {
   background: url('./../../img/paymenticons.svg') 57.142857142857146% 0 no-repeat;
   width: 122px;
   height: 77.36px;
}
.mono-hipercard {
   background: url('./../../img/paymenticons.svg') 57.142857142857146% 11.104731281580156% no-repeat;
   width: 122px;
   height: 77.36px;
}
.mono-jcb {
   background: url('./../../img/paymenticons.svg') 57.142857142857146% 22.20946256316031% no-repeat;
   width: 122px;
   height: 77.36px;
}
.mono-maestro {
   background: url('./../../img/paymenticons.svg') 57.142857142857146% 33.31419384474047% no-repeat;
   width: 122px;
   height: 77.36px;
}
.mono-maestro-old {
   background: url('./../../img/paymenticons.svg') 57.142857142857146% 44.41892512632062% no-repeat;
   width: 122px;
   height: 77.36px;
}
.mono-mastercard {
   background: url('./../../img/paymenticons.svg') 0 66.62838768948095% no-repeat;
   width: 122px;
   height: 77.36px;
}
.mono-mastercard-old {
   background: url('./../../img/paymenticons.svg') 14.285714285714286% 66.62838768948095% no-repeat;
   width: 122px;
   height: 77.36px;
}
.mono-paypal {
   background: url('./../../img/paymenticons.svg') 28.571428571428573% 66.62838768948095% no-repeat;
   width: 122px;
   height: 77.36px;
}
.mono-security-code {
   background: url('./../../img/paymenticons.svg') 42.857142857142854% 66.62838768948095% no-repeat;
   width: 122px;
   height: 77.36px;
}
.mono-unionpay {
   background: url('./../../img/paymenticons.svg') 57.142857142857146% 66.62838768948095% no-repeat;
   width: 122px;
   height: 77.36px;
}
.mono-verve {
   background: url('./../../img/paymenticons.svg') 71.42857142857143% 0 no-repeat;
   width: 122px;
   height: 77.36px;
}
.mono-visa {
   background: url('./../../img/paymenticons.svg') 0 0 no-repeat;
   width: 122px;
   height: 77.36px;
}
.outline-alipay {
   background: url('./../../img/paymenticons.svg') 71.42857142857143% 22.20946256316031% no-repeat;
   width: 122px;
   height: 77.36px;
}
.outline-amex {
   background: url('./../../img/paymenticons.svg') 71.42857142857143% 33.31419384474047% no-repeat;
   width: 122px;
   height: 77.36px;
}
.outline-default {
   background: url('./../../img/paymenticons.svg') 71.42857142857143% 44.41892512632062% no-repeat;
   width: 122px;
   height: 77.36px;
}
.outline-diners {
   background: url('./../../img/paymenticons.svg') 71.42857142857143% 55.52365640790078% no-repeat;
   width: 122px;
   height: 77.36px;
}
.outline-discover {
   background: url('./../../img/paymenticons.svg') 0 77.73311897106109% no-repeat;
   width: 122px;
   height: 77.36px;
}
.outline-elo {
   background: url('./../../img/paymenticons.svg') 14.285714285714286% 77.73311897106109% no-repeat;
   width: 122px;
   height: 77.36px;
}
.outline-hipercard {
   background: url('./../../img/paymenticons.svg') 28.571428571428573% 77.73311897106109% no-repeat;
   width: 122px;
   height: 77.36px;
}
.outline-jcb {
   background: url('./../../img/paymenticons.svg') 42.857142857142854% 77.73311897106109% no-repeat;
   width: 122px;
   height: 77.36px;
}
.outline-maestro {
   background: url('./../../img/paymenticons.svg') 57.142857142857146% 77.73311897106109% no-repeat;
   width: 122px;
   height: 77.36px;
}
.outline-maestro-old {
   background: url('./../../img/paymenticons.svg') 71.42857142857143% 77.73311897106109% no-repeat;
   width: 122px;
   height: 77.36px;
}
.outline-mastercard {
   background: url('./../../img/paymenticons.svg') 0 88.83785025264125% no-repeat;
   width: 122px;
   height: 77.36px;
}
.outline-mastercard-old {
   background: url('./../../img/paymenticons.svg') 14.285714285714286% 88.83785025264125% no-repeat;
   width: 122px;
   height: 77.36px;
}
.outline-paypal {
   background: url('./../../img/paymenticons.svg') 28.571428571428573% 88.83785025264125% no-repeat;
   width: 122px;
   height: 77.36px;
}
.outline-security-code {
   background: url('./../../img/paymenticons.svg') 42.857142857142854% 88.83785025264125% no-repeat;
   width: 122px;
   height: 77.36px;
}
.outline-unionpay {
   background: url('./../../img/paymenticons.svg') 57.142857142857146% 88.83785025264125% no-repeat;
   width: 122px;
   height: 77.36px;
}
.outline-verve {
   background: url('./../../img/paymenticons.svg') 71.42857142857143% 88.83785025264125% no-repeat;
   width: 122px;
   height: 77.36px;
}
.outline-visa {
   background: url('./../../img/paymenticons.svg') 85.71428571428571% 0 no-repeat;
   width: 122px;
   height: 77.36px;
}
.single-alipay {
   background: url('./../../img/paymenticons.svg') 85.71428571428571% 11.104731281580156% no-repeat;
   width: 122px;
   height: 77.36px;
}
.single-amex {
   background: url('./../../img/paymenticons.svg') 85.71428571428571% 22.20946256316031% no-repeat;
   width: 122px;
   height: 77.36px;
}
.single-diners {
   background: url('./../../img/paymenticons.svg') 85.71428571428571% 33.31419384474047% no-repeat;
   width: 122px;
   height: 77.36px;
}
.single-discover {
   background: url('./../../img/paymenticons.svg') 85.71428571428571% 44.52886663213032% no-repeat;
   width: 122px;
   height: 79.08px;
}
.single-elo {
   background: url('./../../img/paymenticons.svg') 85.71428571428571% 53.36886495693622% no-repeat;
   width: 122px;
   height: 46.01px;
}
.single-hipercard {
   background: url('./../../img/paymenticons.svg') 85.71428571428571% 62.52949979853796% no-repeat;
   width: 122px;
   height: 79.08px;
}
.single-jcb {
   background: url('./../../img/paymenticons.svg') 85.71428571428571% 73.72674552135967% no-repeat;
   width: 122px;
   height: 77.36px;
}
.single-maestro {
   background: url('./../../img/paymenticons.svg') 85.71428571428571% 87.0443197384119% no-repeat;
   width: 122px;
   height: 95.07px;
}
.single-maestro-old {
   background: url('./../../img/paymenticons.svg') 0 99.9425815342214% no-repeat;
   width: 122px;
   height: 77.36px;
}
.single-mastercard {
   background: url('./../../img/paymenticons.svg') 100% 0 no-repeat;
   width: 122px;
   height: 95.01px;
}
.single-mastercard-old {
   background: url('./../../img/paymenticons.svg') 100% 13.638321084060633% no-repeat;
   width: 122px;
   height: 77.36px;
}
.single-paypal {
   background: url('./../../img/paymenticons.svg') 100% 24.80429401945548% no-repeat;
   width: 122px;
   height: 79.08px;
}
.single-unionpay {
   background: url('./../../img/paymenticons.svg') 100% 36.0946830500689% no-repeat;
   width: 122px;
   height: 77.36px;
}
.single-verve {
   background: url('./../../img/paymenticons.svg') 100% 45.375634797968644% no-repeat;
   width: 122px;
   height: 49.36px;
}
.single-visa {
   background: url('./../../img/paymenticons.svg') 100% 54.28485300872761% no-repeat;
   width: 122px;
   height: 77.36px;
}
