@import '../../../../assets/styles/utils/_variables';
@import '../../../../assets/styles/utils/_mixins';

.moedas-offers-day {
  display: flex;
  align-items: center;
  margin: 0 auto 0 auto;
  width: 100%;
  max-width: $max-width;
  padding-bottom: 72px;

  .swiper-container {
    display: flex;
  }


  .btn {
    position: absolute;
    z-index: 11;
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 7px;
    box-shadow: 0 3px 20px 0 rgb(0 0 0 / 16%);
    color: $primary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 185px;
  }

  .prev {
    display: none;

    @include respond-to('medium') {
      display: flex;
      left: -17px;
    }
  }

  .next {
    display: none;

    @include respond-to('medium') {
      display: flex;
      right: -17px;
    }
  }
}
