@import 'variables';
@import 'mixins';

.moedas-faq {
   padding-bottom: 40px;
   background: $background-header;

   &__container {
      .moedas-search-faq {
         background: $primary-color;
         width: 100%;
         display: grid;
         grid-template-columns: 80px 1fr 80px;
         justify-content: space-between;
         align-items: center;

         @include respond-to('medium') {
            height: 243px;
            grid-template-columns: 1fr 1fr 1fr;
         }

         &__container {
            display: grid;
            align-items: center;
            margin: 0 auto;
            gap: 12px;
            width: 100%;
            max-width: 237px;
            text-align: center;
            padding: 24px 0;
            @include respond-to('medium') {
               gap: 34px;
               padding: 0;
               max-width: max-content;
            }

            h1 {
               color: white;
               font-size: 16px;
               line-height: 33px;
               font-weight: 600;
               font-family: $font-family-moedas-semi-bold;

               @include respond-to('medium') {
                  font-size: 24px;
               }
            }

            p {
               color: white;
               font-size: 16px;
               line-height: 19px;
               font-weight: 300;

               @include respond-to('medium') {
                  font-size: 22px;
                  line-height: 22px;
               }
            }

            .search-header {
               display: none;
               @include respond-to('medium') {
                  display: block;
               }
            }
         }

         .detail1 {
            height: 82px;
            object-position: -41px;
            @include respond-to('medium') {
               height: 126px;
            }
         }

         .detail2 {
            height: 82px;
            justify-self: flex-end;
            transform: scaleX(-1);
            object-position: -41px;
            @include respond-to('medium') {
               height: 126px;
            }
         }
      }

      .moedas-accordion-faq {
         width: 100%;

         @include respond-to('medium') {
         }

         &__container {
            width: 100%;
            max-width: $max-width;
            margin: 0 auto;
            margin-top: 40px;

            .titulo-faq {
               font-size: 20px;
               font-weight: 600;
               line-height: 27px;
               margin-bottom: 32px;
               margin-left: 16px;
               margin-top: 40px;
               @include respond-to('medium') {
                  margin-left: 0;
               }
            }

            .list-faq {
               background: white;
               height: 100%;
               padding: 32px;
               border: solid 1px #e3e3e3;
               border-radius: 7px;
               margin: 0 1rem;
               @include respond-to('medium') {
                  margin: 0;
               }
            }

            dt {
               font-size: 16px;
               line-height: 26px;
               font-weight: 600;
               display: flex;
               justify-content: space-between;
               user-select: none;
               img {
                  cursor: pointer;
               }
            }

            .sub-text {
               margin-top: 16px;
               display: none;
               color: #757575;
               font-size: 16px;
               line-height: 26px;
               animation: showAccordion 0.8s forwards;
               overflow-y: auto;

               &--open-accordion {
                  display: block;
                  animation: showAccordion 0.4s forwards;
               }
            }

            .arrow-down {
               transition: 0.4s;
            }
         }
      }

      .moedas-leave-to-contact {
         border: 1px solid #e3e3e3;
         background-color: white;
         margin-top: 40px;
         position: relative;
         top: 41px;
         &__container {
            display: grid;
            justify-content: center;
            gap: 17px;
            h3 {
               font-weight: 300;
               font-size: 1rem;
               margin-top: 40px;
               text-align: center;
               @include respond-to('medium') {
                  font-size: 1.25rem;
               }
            }
            a {
               background: $primary-color;
               border-radius: 21px;
               height: 46px;
               width: 100%;
               font-size: 1rem;
               color: white;
               margin-bottom: 40px;
               max-width: 400px;
               display: flex;
               justify-content: center;
               height: 46px;
               align-items: center;
               transition: ease-out 0.5s;
               @include respond-to('medium') {
                  width: 100%;
                  max-width: 159px;
                  margin: 0 auto 40px auto;
               }
               &:hover {
                  box-shadow: 0 0 0 3px #adf, 0 0 0 4px $primary-color;
                  outline: none;
               }
            }
         }
      }

      .moedas-form-faq {
         background: white;
         margin: 0 auto;

         &__container {
            &-wrapper {
               width: 100%;
               max-width: 1200px;
               margin: 0 auto;

               h2 {
                  font-size: 1.25rem;
                  line-height: 27px;
                  font-weight: 600;
                  margin-bottom: 24px;
               }

               form {
                  width: 100%;
                  max-width: 452px;
               }

               label {
                  margin-bottom: 9px;
               }

               input {
                  margin-bottom: 16px;
               }

               textarea {
                  resize: none;
               }
            }
         }
      }

      // Effect accordion
      @keyframes showAccordion {
         from {
            opacity: 0;
         }

         to {
            opacity: 1;
            max-height: 300px;
         }
      }
   }

   .rotate {
      transform: rotate(180deg);
   }

   // Modify Input
   .search-main {
      display: block;
      padding: 0 1rem;
      position: relative;
      margin-bottom: 1rem;
      top: -20px;
      @include respond-to('medium') {
         display: none;
         padding: 0;
      }
   }
}
