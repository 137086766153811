@import 'mixins';
@import 'variables';

.moedas-department-cards {
  display: flex;
  align-items: center;
  margin: 0 auto 5rem auto;
  width: 100%;
  max-width: $max-width;
  padding: 0 19px;

  @include respond-to('medium') {
    padding: 0;
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    @include respond-to('small') {
      grid-template-columns: 1.5fr 1.2fr 1.5fr;
    }

    @include respond-to('medium') {
      display: flex;
      gap: 1.6rem;
      flex-wrap: wrap;
    }
  }
}
