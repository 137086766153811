// Import Theme Material
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

.mat-snack-bar-container{
   background: transparent !important;
   box-shadow: none !important;
}
.mat-form-field-infix {
   padding: 0 !important;
   top: -5px !important;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
   margin: 0 !important;
}

.mat-select-placeholder {
   font-size: 13px;
}
/* Border */
.mat-form-field-appearance-outline .mat-form-field-outline {
   color: #a5a5a5;
 }

.mat-form-field.mat-focused .mat-form-field-ripple {
   background-color: $primary-color;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
   background-color: #ffffff;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
   height: 48px !important;
   padding: 9px 10px !important;
}

.mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
   color: $primary-color !important;
}

.mat-select-value-text {
   font-size: 13px;
}

.mat-select-panel {
   background: #ffffff !important;
   box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08) !important;
   @include border-radius(0 !important);
   position: relative;
   top: 35px;
   font-size: 13px !important;
}
.mat-option {
   padding: 0 16px;
   margin: 5px 0;
   @include border-radius(4px);
   &.mat-selected {
      &:not(.mat-option-disabled) {
         color: $secondary-on-color !important;
         font-family: $font-family-moedas !important;
         font-size: 1rem !important;
      }
   }
   &.mat-active,
   &:hover {
      background: $light-blue !important;
      color: $primary-color !important;
   }
}
.mat-option-text {
   display: flex !important;
   justify-content: space-between;
   align-items: center;
}

.mat-select-arrow-wrapper {
   &:before {
      content: "\e5cf";
      color: $primary-color;
      font-family: "Material Icons";
      margin-right: 4px;
      font: {
         size: 24px;
      }
   }
   .mat-select-arrow {
      display: none;
   }
}

.mini-select{
   display: flex;
   justify-content: center;
   label{
      display: none;
   }
   .mat-form-field-wrapper{
      padding: 0 !important;
   }

   .mat-form-field{
      height: 34px !important;
      width: 58px;
   }

   .mat-form-field-appearance-outline .mat-form-field-flex{
      height: 34px !important;
      padding: 0 !important;
   }
   .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
      transform: translate(3px, -3px) !important;
  }
  .mat-form-field-infix{
   border-top: none !important;
   top: 9px !important;
   padding: 0 0 0 8px !important;
  }

  .mat-form-field-label-wrapper {
      top: 0;
      padding-top: 0;
   }
}

.mini-select-search {
   display: flex;
   justify-content: center;
   label{
      display: none;
   }
   .mat-form-field-wrapper{
      padding: 0 !important;
   }

   .mat-form-field{
      height: 34px !important;
      width: 135px;
   }

   .mat-form-field-appearance-outline .mat-form-field-flex{
      height: 34px !important;
      padding: 0 !important;
   }
   .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
      transform: translate(3px, -3px) !important;
  }
  .mat-form-field-infix{
   border-top: none !important;
   top: 9px !important;
   padding: 0 0 0 8px !important;
  }

  .mat-form-field-label-wrapper {
      top: 0;
      padding-top: 0;
   }
}


//AUTO COMPELTE
.mat-autocomplete-panel.mat-autocomplete-visible {
   padding: 0 !important;
   background: #fff !important;
   box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08) !important;
   @include border-radius(0 !important);
   max-height: 215px;
   font-size: 13px !important;
}

