@import 'mixins';
@import 'variables';

.call-to-action-moedas {
   width: 100vw;
   max-width: 1200px;
   height: 444px;
   position: relative;
   top: -4px;
   @include respond-to('small') {
      height: 200px;
      top: 0;
   }
   @include respond-to('medium') {
      margin: 4rem auto;
      padding: 0;
      height: 444px;
   }

   &__container {
      width: 100%;
      height: 100%;
      position: relative;
      border: solid 1px $border-color;
      border-radius: 7px;
      overflow: hidden;
      display: grid;
      height: 100%;
      grid-template-columns: 1fr;
      grid-template-rows: 0.7fr 1fr;
      height: 400px;
      grid-template-areas:
         'carousel'
         'swiper';
      @include respond-to('small') {
         grid-template-columns: 402px 796px;
         grid-template-rows: 1fr;
         grid-template-areas: 'swiper carousel';
         height: 12.5rem;
      }
      @include respond-to('medium') {
         height: 444px;
      }

      &-swiper {
         grid-area: swiper;
         background: #fff;
         max-width: calc(107vw - 32px);
         max-height: 100%;
         @include respond-to('medium') {
            max-width: 100%;
         }
         .swiper-container {
            height: $call-to-action-height;
         }

         .swiper-pagination {
            bottom: 16.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: -5px auto;
            &-bullet {
               width: 13px;
               height: 13px;
               &-active {
                  width: 18px;
                  height: 18px;
               }
            }
            @include respond-to('medium') {
               margin: 0 0 0 75px;
               justify-content: flex-start;
               bottom: 95px;
               &-bullet {
                  width: 8px;
                  height: 8px;
                  &-active {
                     width: 12px;
                     height: 12px;
                  }
               }
            }
         }

         &-content {
            display: grid;
            justify-content: center;

            @include respond-to('medium') {
               margin: 85px 45px 12.5px 77px;
               display: block;
            }
            h3 {
               font-weight: 500;
               width: 100%;
               max-width: 18.5rem;
               line-height: 1.35;
               text-align: left;
               margin: 24px 0 0 24px;
               @media (min-width: 412px) {
                  margin: 24px auto;
               }
               font: {
                  size: 1.25rem;
                  family: $font-family-moedas;
               }
               @include respond-to('medium') {
                  font-size: 1.78rem;
                  line-height: 1.36;
                  max-width: 15.75rem;
                  margin: 0 0 1.25rem 0;
               }
            }
            button {
               width: 125px;
               height: 36px;
               padding: 9px 30px 8px;
               border-radius: 50px;
               display: flex;
               margin: 6px auto;
               @include respond-to('medium') {
                  margin: 0;
               }
            }
         }
      }

      &-carousel {
         grid-area: carousel;
         max-width: $max-width-swiper-mobile;
         background: #fff;
         max-height: 100%;
         .swiper-wrapper {
            padding: 0 !important;
         }

         &-img {
            width: 100%;
            height: 186px;
            object-fit: cover;
            @include respond-to('small') {
               height: 12.5rem;
            }
            @include respond-to('medium') {
               position: relative;
               top: -4px;
            }
         }

         @include respond-to('medium') {
            max-width: 100%;
            &-img {
               height: $call-to-action-height;
               border-radius: 0;
            }
         }
      }

      &-btn {
         position: absolute;
         top: 0;
         bottom: 0;
         z-index: 11;
         width: 30px;
         height: 30px;
         background: white;
         border-radius: 7px;
         box-shadow: 0 3px 20px 0 rgb(0 0 0 / 16%);
         color: $primary-color;
         display: none;
         justify-content: center;
         align-items: center;
         margin: auto;
         @include respond-to('medium') {
            display: flex;
         }
         &:focus,
         &:hover {
            background: #fff;
            outline: none;
            border: none;
            box-shadow: 0 3px 20px 0 rgb(0 0 0 / 16%);
         }

         &--prev {
            left: 24px;
         }

         &--next {
            right: 24px;
         }
      }
   }
}
