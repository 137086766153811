@import '~@angular/cdk/overlay-prebuilt.css';

.cdk-global-scrollblock {
   overflow-y: hidden;
   top: 0 !important;
}
.cdk-global-overlay-wrapper {
   overflow: auto;
   display: flex;
   align-items: center;
   justify-content: center;
}

.custom-modal {
   width: 100%;
   min-height: 100vh;
   padding: 16px 24px 32px;
   background: #fff;
   overflow: auto;
   display: block;
   position: relative !important;
   @include respond-to('medium') {
      width: 35vw;
      min-height: 96vh;
      border-radius: 10px;
      padding: 16px 40px 32px;
   }
   @include respond-to('extra-large') {
      width: 30vw;
      min-height: 80vh;
   }
}

.modal-card {
   min-height: 11vh;
}

.modal-pix {
   min-height: 40vh;
}

.background-modal {
   background: rgba(0, 0, 0, 0.8);
}

.documents-modal{
   max-height: 85vh;
}

.trackBox {
   min-height: 384px;
   width: 80%;
   border-radius: 8px;
   @include respond-to('medium') {
      min-height: 440px;
      width: 35%;
   }
}

.askBox {
   min-height: 384px;
   width: 80%;
   border-radius: 8px;
   @include respond-to('medium') {
      min-height: 440px;
      width: 35%;
   }
}

.modalConfirmation {
   min-height: 384px;
   width: 80%;
   border-radius: 8px;
   display: grid;
   justify-content: center;
   align-items: center;
   @include respond-to('medium') {
      width: 40%;
   }
}
