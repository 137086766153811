@import 'variables';
@import 'mixins';


%list-style {
   li {
      text-align: left;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      padding: 10px 0 10px 24px;
      &:hover {
         @include transition(0.4s);
         background: #f0f5ff;
         color: $primary-color;
      }
   }
}

.hidden-search {
   @include respond-to('medium') {
      display: none;
   }
}

.moedas-header {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: flex-start;
   position: fixed;
   top: 0;
   border-bottom: 1px solid #eaeaeaea;
   overflow: hidden;
   @include transition(0.2s);
   height: $hight-header;
   background: #fff;
   z-index: 5;
   @include respond-to('medium') {
      align-items: center;
      position: inherit;
      padding: 0;
   }

   hr {
      background: #e3e3e3;
      opacity: 0.3;
   }

   &--search-opened {
      height: 128px;
   }

   &__container {
      text-align: center;
      display: grid;
      align-items: center;
      grid-template-columns: 0.5fr 1fr 0.4fr 0.7fr 0.2fr;
      max-width: $max-width;
      width: 100%;
      gap: 1rem;
      margin: 10px 0 0.4rem;

      @include respond-to('medium') {
         margin: 0;
         gap: 2rem;
         border-bottom: 0;
         grid-template-columns: 3fr 7fr 1fr 0.5fr 0.5fr;
      }

      /* Logado */

      &--logged {
         @include respond-to('medium') {
            grid-template-columns: 3fr 7fr 1fr 0.5fr 0.5fr 1fr;
         }
      }

      .box-auth {

         &__container {
            display: flex;
            align-items: center;
            cursor: pointer;
         }
         .box-notification {
            position: relative;
            width: 50px;
            &-msg {
               position: absolute;
               left: 30px;
               width: 10px;
               height: 10px;
               border-radius: 50%;
               background-color: #1251ff;
               top: 2px;
            }
            &-shop-cart {
               height: 24px;
            }
         }

         @include respond-to('medium') {
            gap: 0.9rem;
         }

         .dropdown-user {
            position: absolute;
            top: 87px;
            background-color: white;
            width: 100%;
            max-width: 217px;
            z-index: 20;
            display: grid;
            padding: 21px 0;
            display: grid;
            border-radius: 7px;
            box-shadow: 2px 2px 7px #ccc;

            .level-stars {
               display: flex;
               align-items: center;
               justify-content: flex-start;
               margin: 8px 24px;
               span {
                  width: 10px;
                  height: 10px;
                  border: solid 1px $border-color;
                  background: $border-color;
                  border-radius: 50%;
                  &:not(:last-child) {
                     margin-right: 10px;
                  }
                  @include respond-to('medium') {
                     width: 10px;
                     height: 10px;
                     &:not(:last-child) {
                        margin-right: 16px;
                     }
                  }
               }
            }

            &--enable {
               display: grid;
            }

            &-hr {
               margin: 8px 0;
            }

            &-nav {
               @extend %list-style;
            }

            &-info {
               display: flex;
               width: 100%;
               font-size: 14px;
               align-items: baseline;
               padding: 0 24px;
               strong {
                  margin: 0 10px;
                  font-size: 14px;
                  font-weight: bold;
                  color: $primary-color;
               }

               img {
                  width: 8px;
                  height: 10px;
                  display: block;
                  cursor: pointer;
               }
            }

            &-exit {
               margin: 7px 24px 0;
               span {
               cursor: pointer;
               display: flex;
               align-items: center;
               width: 60px;
                  img {
                     width: 16px;
                     height: 19px;
                     margin-right: 12px;
                  }

                  a {
                     color: $secondary-on-color;
                     font-size: 16px;
                     font-weight: 600;
                  }
               }

            }
         }
      }

      img {
         width: 100%;
         max-width: 115px;
         height: 74px;

         @include respond-to('medium') {
            max-width: $width-logo;
            height: initial;
         }
      }

      div:nth-child(5) {
         display: none;
         justify-content: center;
         align-items: center;

         @include respond-to('medium') {
            display: flex;

         }
      }

      .search {
         display: none;
         @include respond-to('medium') {
            display: block;
         }

         &--mobile {
            display: block;
            width: calc(100% - 32px);
            margin: auto;
            position: relative;
            bottom: 10px;
            grid-column: 1/6;
            opacity: 0;
            @include transition(0.4s);
            @include respond-to('medium') {
               display: none;
            }
         }
         &--opened {
            opacity: 1 !important;
         }
      }

      &-category {

         &-dep {
            justify-content: center;
            align-items: center;
            display: none;
            cursor: pointer;
            @include respond-to('medium') {
               display: flex;
            }

            a {
               font-size: 14px;
               color: $secondary-on-color;
               font-weight: 600;
               margin-right: 8px;
            }
         }

         .dropdown-dep {
            position: absolute;
            top: 87px;
            background-color: white;
            width: 100%;
            max-width: 217px;
            z-index: 20;
            display: none;
            border-radius: 7px;
            box-shadow: 2px 2px 7px #ccc;
            overflow-y: hidden;

            &--active {
               display: grid;
            }

            ul {
               @extend %list-style;
            }
         }
      }

      .favorite {
         color: $secondary-color;
         cursor: pointer;
         margin-right: 1rem;
      }

      .shop-cart {
         cursor: pointer;
      }

      &-user {
         display: none;
         justify-content: center;
         align-items: center;
         gap: 0.4rem;
         font-weight: 600;
         margin-left: 2rem;

         @include respond-to('medium') {
            display: flex;
         }

         i {
            color: $primary-color;
            font-size: 2rem;
         }
      }

      &-account {
         transition: 0.2s;
         color: $primary-color !important;
         white-space: nowrap;
         display: none;
         font-size: 14px;
         font-weight: 600;
         line-height: 1.36;

         @include respond-to('medium') {
            display: flex;
         }

         &:hover {
            opacity: 0.6;
         }
      }
      &-search {
         background: transparent;
         display: block;
         background: $secondary-color;
         mask: url('./../../../../assets/img/search-icon.svg') no-repeat center;
         height: 32px;
         &--arrow {
            mask: url('./../../../../assets/img/arrow-up.svg') no-repeat center;
         }
         &:focus {
            box-shadow: none;
         }
         @include respond-to('medium') {
            display: none;
         }
      }

      .m-right {
         font-family: open-sans-semibold;
         font-size: 14px;
         font-weight: 600;
         color: #757575;
      }

      .m-right::after {
         content: '';
         padding: 0;

         @include respond-to('medium') {
            padding: 14px 1px;
            background-color: #e3e3e3;
            opacity: 0.5;
            margin-left: 1.5rem;
         }
      }

      button {
         padding: 0.4rem 14px;
         font-weight: 400;
         position: relative;
         right: 8px;

         @include respond-to('medium') {
            padding: 0.6rem 1.9rem;
            font-size: 1rem;
         }
      }
   }

   .user-avatar {
      width: 36px;
      height: 36px;
      overflow: hidden;
      border-radius: 50%;
      margin-left: 23px;
      @include respond-to('medium') {
         width: 24px;
         height: 24px;
         margin-left: 0;
      }
   }

   // menu mobile
   .moedas-menu-mobile {
      height: 100%;
      display: grid;
      justify-content: center;
      margin-bottom: 0.5rem;
      align-items: center; // aqui

      @include respond-to('medium') {
         display: none;
         margin-bottom: 0;
      }
   }

   #checkbox-menu {
      position: relative;
      opacity: 0;
   }

   label {
      cursor: pointer;
      position: relative;
      display: block;
      height: 22px;
      width: 30px;
   }

   label span {
      position: absolute;
      display: block;
      height: 5px;
      width: 100%;
      border-radius: 30px;
      background: $primary-color;
      transition: 0.25s ease-in-out;
   }

   label span:nth-child(1) {
      top: 0;
      height: 3px;
   }

   label span:nth-child(2) {
      top: 8px;
      height: 3px;
   }

   label span:nth-child(3) {
      top: 16px;
      height: 3px;
   }

   #checkbox-menu:checked + label span:nth-child(1) {
      transform: rotate(-45deg);
      top: 8px;
   }

   #checkbox-menu:checked + label span:nth-child(2) {
      opacity: 0;
   }

   #checkbox-menu:checked + label span:nth-child(3) {
      transform: rotate(45deg);
      top: 8px;
   }

   .moedas-menu-mobile-container {
      display: none;
      position: absolute;
      top: 65px;
      background: white;
      text-align: center;
      opacity: 0;
      transform: translateY(-22px);
      gap: 19px;

      button {
         font-family: $font-family-moedas;
         background: white;
         color: $primary-color;
         border: 1px solid #e3e3e3;
         font-size: 16px;
         font-weight: 600;
         line-height: 1.38;
         display: flex;
         justify-content: center;
         align-items: center;
         width: 100%;
         max-width: 332px;
         height: 44px;
      }

      .user-hr {
         background: #e3e3e3;
         height: 1px;
         opacity: 0.3;
         margin: 24px 0 30px 0;
      }

      &--mobile-on {
         width: 100%;
         display: grid;
         position: fixed;
         z-index: 15;
         animation: showIn 0.3s ease-out forwards;
         padding: 0 !important;

         @include respond-to('medium') {
            display: none;
         }

         button {
            margin: 0 auto;
            background: $btn-on-color;
            border: 1px solid $primary-color;
         }

         .no-auth {
            margin: 19px 0 8px 0;
            gap: 19px;
            display: grid;
         }

         .offers {
            padding: 0 19px;
            height: 800px;
            overflow-y: auto;

            .dep-hr {
               height: 1px;
               margin: 24px 0 30px 0;
            }

            .exit {
               display: flex;
               width: 100%;
               max-width: 80px;
               &:hover {
                  opacity: 0.8;
               }
               img {
                  width: 20px;
                  height: 24px;
                  cursor: pointer;
               }
               p {
                  margin-left: 16px;
                  font-size: 16px;
                  font-weight: 600;
                  color: $secondary-on-color;
               }
            }

            @include respond-to('medium') {
               padding: 0;
            }

            &-title {
               display: flex;
               align-items: baseline;
               justify-content: space-between;
               width: 100%;

               > div {
                  display: flex;
                  justify-content: baseline;
                  align-items: center;
                  cursor: pointer;

                  a {
                     font-size: 12px;
                  }

                  img {
                     margin-left: 8px;
                     width: 100%;
                     max-width: 16px;
                  }
               }

               h2 {
                  font-weight: 500;
                  color: #222;
                  font-size: 20px;
                  margin: 0 0 2rem 0;

                  @include respond-to('medium') {
                     font-size: 1.75em;
                  }
               }
               a {
                  color: $primary-color;
                  font-size: 14px;
                  font-weight: 500;

                  &:hover {
                     opacity: 0.6;
                  }
               }
            }

            .moedas-department-cards-header {
               display: flex;
               align-items: center;
               width: 100%;
               max-width: $max-width;

               @include respond-to('medium') {
                 padding: 0;
               }

               &__container {
                 display: grid;
                 grid-template-columns: 1fr 1fr;
                 gap: 1rem;

                 @include respond-to('small') {
                   grid-template-columns: 1.5fr 1.2fr 1.5fr;
                 }

                 @include respond-to('medium') {
                   display: flex;
                   gap: 1.6rem;
                   flex-wrap: wrap;
                 }
               }
             }
         }
      }

      @keyframes showIn {
         to {
            transform: translateY(22px);
            opacity: 1;
         }
      }
   }

   /* Logado Menu Mobile */
   .moedas-menu-mobile-container-auth {
      width: 100%;
      overflow-y: scroll;
      height: 100vh;
      &__container {
         display: grid;
         grid-template-columns: 80px 1fr;
         align-items: center;
         margin-bottom: 1rem;

         i {
            font-size: 2.8rem;
            color: $primary-color;
         }

         &-info {
            display: grid;
            text-align: initial;
            margin-top: 22px;
            cursor: pointer;
            p {
               font-size: 16px;
               font-weight: 600;
               font-family: $font-family-moedas-semi-bold;
            }

            .level-user {
               display: grid;
               gap: 9px;
               justify-items: baseline;

               &__container {
                  display: flex;
                  gap: 11px;
                  align-items: center;

                  span {
                     font-size: 14px;
                  }

                  strong {
                     color: $primary-color;
                     font-size: 14px;
                  }

                  img {
                     width: 100%;
                     max-width: 5px;
                     height: 9px;
                     transform: rotate(180deg);
                     cursor: pointer;
                  }
               }

               .level__stars {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  span {
                     width: 12px;
                     height: 12px;
                     border: solid 1px $border-color;
                     background: $border-color;
                     border-radius: 50%;
                     &:not(:last-child) {
                        margin-right: 10px;
                     }
                     @include respond-to('medium') {
                        width: 17px;
                        height: 17px;
                        &:not(:last-child) {
                           margin-right: 16px;
                        }
                     }
                  }
               }
            }
         }
      }
      .list-user {
         z-index: 2;
         background: #fff;
         width: 100%;
         nav {
            text-align: left;
            display: grid;
            gap: 2.2rem;
            margin: 19px 19px;
            .side-menu-header {
               p {
                  color: #222222;
                  display: flex;
                  align-items: center;
                  font-family: "open-sans-semibold";
                  font-weight: 900;
                  font-size: 0.875rem;
               }
            }
            ul {
               .hr-user {
                  margin-top: 1rem;
               }
                li {
                  padding: 10px 0;
                  cursor: pointer;
                  color: #222222;
                  transition: 0.2s;
                  font-size: 0.875rem;
                  font-family: "Open Sans";
                   @include transition(0.2s);
                   &:hover {
                     color: $primary-color;
                   }
                }
            }
         }
      }
   }
}

.auto-complete {
   position: fixed;
   z-index: 2;
   background: #fff;
   width: 100%;
   height: 100vh;
   overflow: hidden;
   margin-top: 38px;
   @include transform(translateY(-100%));
   visibility: hidden;
   @include transition(0.2s);
   &--show {
      visibility: visible;
      @include transform(translateY(0));
   }

   &__list {
      width: calc(100% - 32px);
      margin: auto;
      padding: 6px;
      li {
         height: 52px;
         margin: 1px 0;
         padding: 15px 0;
         border-bottom: solid 1px $border-color;
         display: flex;
         align-items: center;
         justify-content: space-between;
         @include transition(0.3s);
         &:hover {
            background: darken(#fff, 1.5%);
         }
      }
   }
}

.fixed {
   position: fixed;
   background: white;
   width: 100%;
   padding: 0;
   z-index: 20;
}

// Box modal
.modal-information {
   max-width: 300px;
   width: 100%;
   min-height: 280px;
   padding: 1rem;
}
