*,
*::after,
*::before {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
}

a {
   text-decoration: none;
   color: inherit;
   cursor: pointer;
}

pre {
   font-family: var(--font) , monospace;
   white-space: pre-wrap;
}

html {
   --font: 'Open Sans';
   --font-size: 0.9rem;
   --color-button-text: #fff;
   --color: #115bfb;
   --color-light: #adf;
   --color-dark: #018;
   --radius: 30px;
   --radius-input: 4px;
   --line-hight: 20px;
   --line-hight-cto: 41px;
   scroll-behavior: smooth;
}
body{
   cursor: default !important;
}
body,
input,
textarea,
button {
   font-family: var(--font);
}

img {
   display: block;
}

h1,
h2,
h3 {
   font-family: var(--font);
   color: #222;
}

p {
   font-size: 14px;
}

ul {
   margin: 0;
   list-style-type: none;
}

li {
   font-family: var(--font);
   line-height: var(--line-hight);
}

label {
   display: block;
   margin-bottom: 0.25rem;
}

input,
textarea,
select {
   display: block;
   border: 1px solid #ccc;
   width: 100%;
   padding: 0.8rem;
   border-radius: var(--radius-input);
   background: white;
   transition: 0.2s;
   position: relative;
}

select {
   border-color: #9c9c9c;
}

input:focus,
textarea:focus,
select:focus {
   outline: none;
   border-color: var(--color);
   background: #eee;
   box-shadow: 0 0 0 1px var(--color);
}
.invalid {
   border-color: #f00 !important;
}

button {
   cursor: pointer;
   line-height: var(--line-hight);
   border: none;
   border-radius: var(--radius);
   transition: 0.1s;
   background: var(--color);
   color: var(--color-button-text);
   font-weight: 600;
}

button:hover,
button:focus {
   background: var(--color);
   box-shadow: 0 0 0 3px var(--color-light), 0 0 0 4px var(--color);
   outline: none;
}

button:active {
   box-shadow: 0 0 0 3px var(--color), 0 0 0 4px var(--color);
}

button:disabled {
   opacity: 0.5;
   cursor: default;
}

// Remove control input number
input[type='number'] {
   -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
}

.btn-loader {
   position: relative;
   user-select: none;
   @include transition(0.3s);
   &:after {
      content: '';
      width: 1.2em;
      height: 1.2em;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      border: 0.2em solid transparent;
      border-right-color: #fff;
      border-radius: 50%;
      opacity: 0;
   }
   &--start {
      color: transparent;
      pointer-events: none;
      &:after {
         opacity: 1;
         animation: button-anim 0.7s linear infinite;
      }
   }
}
@keyframes button-anim {
   from {
      transform: rotate(0);
   }
   to {
      transform: rotate(360deg);
   }
}

/*
  Estilo Global Swiper
*/

.swiper-wrapper {
   // padding: 0 1rem;
   margin-top: 4px;
   @include respond-to('medium') {
      padding: 0;
   }
}
///Estilos responsivos dos carrousel de cards
.carrousel {
   max-width: 100vw;
   margin: 0 auto 45px;
   position: relative;
   .swiper-slide {
      width: 92%;
   }

   @media (min-width: 361px) {
      .swiper-slide {
         width: 83%;
      }
   }

   @include respond-to('large') {
      max-width: $max-width;
      padding-left: 0;
      margin: 0;
      .swiper-slide {
         width: 100%;
      }
   }
}

//SCROLL STYLE
::-webkit-scrollbar {
   width: 2px;
   @include respond-to('medium') {
      width: 5px;
   }
}
::-webkit-scrollbar-track {
   box-shadow: inset 0 0 5px grey;
   border-radius: 10px;
}
::-webkit-scrollbar-thumb {
   background: lighten(gray, 20%);
   border-radius: 10px;
   &:hover {
      background: lighten(gray, 10%);
   }
}

.scroll{
   overflow-x: auto;
   overflow-y: hidden;
   white-space: nowrap;
   &::-webkit-scrollbar {
      display: none;
    }
   &__roll{
      width: 700px;

      @include respond-to('medium'){
         width: 100%;
      }
   }
}

.page-title {
   line-height: 1.36;
   color: $dark;
   margin-bottom: 17px;
   padding: 0 16px;
   font: {
      family: $font-family-moedas;
      weight: 400;
      size: 1.75rem;
   }
   @include respond-to('medium') {
      margin-bottom: 32px;
      padding: 0;
   }
}

.options-button {
   color: $primary-color;
   position: absolute;
   bottom: 24px;
   right: 24px;
   padding-right: 30px;
   background: url('./../../img/tool-icon.svg') no-repeat 90%;
   background-size: 15px 15px;
   font: {
      size: 1rem;
      family: $font-family-moedas;
      weight: normal;
   }
   &:hover,
   &:focus {
      box-shadow: none;
      background: url('./../../img/tool-icon.svg') no-repeat 90%;
   }
   @include respond-to('medium') {
      position: relative;
      top: 0;
   }

   &:disabled{
      color: $secondary-color;
      cursor: default;
      user-select: none;
   }
}

.loading{
   display:flex;
   justify-content: center;
}


.msg-error{
   color: #f00;
   font-size: 12px;
}


@media print
{
   .hide-print{
      display: none !important;
   }
}
